export default {
  name: "ListMCif",
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        animation: {
          checkingCif: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        modal: {
          showModalCheckingCif: false,
          showModalResultCheckingCif: false,
          showModalDownloadCsv: false,
        },
        listElement: {
          cif: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          cifIdName: "",
          categoryId: "",
          cifNumber: "",
          officeCode: "",
          statusId: "",
          downloadCsv: {
            officeCode: "",
            dateFrom: "",
            dateTo: "",
          },
        },
      },
      dataForm: {
        file: "",
        cifIdCardNumber: "",
        cifMotherName: "",
        tipeIdentitas: "ktp",
        totalCif: 0,
        messageApuppt: "",
      },
      session: {
        cifIdCardNumber: "",
        cifMotherName: "",
      },
      options: {
        cifCategory: [],
        office: [],
        statusAccount: [],
        officeCsv: [],
      },
      table: {
        data: {
          cif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFileIdn() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        dateFrom: this.property.filterDto.downloadCsv.dateFrom,
        dateTo: this.property.filterDto.downloadCsv.dateTo,
        officeCode: this.property.filterDto.downloadCsv.officeCode,
        isComaDelimiter: false,
        fileType: 'csv',
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "cif/download-csv",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Download_Data_CIF.csv`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        this.closeModalDownloadCsv();
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.pdf.src = null;
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        this.property.modal.showModalDownloadCsv = false;
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadFileUs() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        dateFrom: this.property.filterDto.downloadCsv.dateFrom,
        dateTo: this.property.filterDto.downloadCsv.dateTo,
        officeCode: this.property.filterDto.downloadCsv.officeCode,
        isComaDelimiter: true,
        fileType: 'csv',
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "transaction",
          reqUrl: "cif/download-csv",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Download_Data_CIF.csv`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        this.closeModalDownloadCsv();
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.pdf.src = null;
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        this.property.modal.showModalDownloadCsv = false;
        this.property.animation.isDownloadingFile = false;
      }
    },
    openModalDownloadCsv() {
      this.property.modal.showModalDownloadCsv = true;
    },
    closeModalDownloadCsv() {
      this.property.modal.showModalDownloadCsv = false;
      this.property.filterDto.downloadCsv.officeCode = "";
      this.property.filterDto.downloadCsv.dateFrom = "";
      this.property.filterDto.downloadCsv.dateTo = "";
    },
    async handleAuthorizationCif(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "cif/validation-dttot/authorized/" + props.row.cifId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.cif.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.categoryId = "";
                  this.property.filterDto.cifNumber = "";
                  this.property.listElement.cif.rows = 0;
                  this.helperGetDataTableCif();
                }, 1000);
              } else if (
                resp.data.code === "THIS_CIF_DATA_IS_A_SUGGESTED_TERRORIST"
              ) {
                if (window.navigator.onLine === false) {
                  this.$buefy.toast.open(
                    this.$NotificationUtils.connectionsError
                  );
                  return;
                } else {
                  this.$buefy.dialog.confirm({
                    message: `${resp.data.message}`,
                    type: "is-info",
                    onConfirm: async () => {
                      try {
                        const resp = await this.$store.dispatch({
                          type: "PUT_DATA_AUTHORIZED",
                          endPoint: "transaction",
                          reqUrl: "cif/authorized/" + props.row.cifId,
                        });
                        if (resp.data.code === "SUCCESS") {
                          this.$buefy.toast.open(
                            this.$NotificationUtils.success
                          );
                          setTimeout(() => {
                            this.property.listElement.cif.currentPage = 1;
                            this.property.filterDto.cifIdName = "";
                            this.property.filterDto.categoryId = "";
                            this.property.filterDto.cifNumber = "";
                            this.property.listElement.cif.rows = 0;
                            this.helperGetDataTableCif();
                          }, 1000);
                        } else if (
                          resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS"
                        ) {
                          this.$buefy.dialog.alert({
                            title: "Harap Tunggu !",
                            message: resp.data.errorFields
                              ? this.globalNotificationErrorFieldToText(
                                  resp.data.errorFields
                                )
                              : resp.data.message,
                            confirmText: "Ok",
                            type: "is-danger",
                          });
                        } else {
                          this.$buefy.dialog.alert({
                            title: "Gagal !",
                            message: `${resp.data.message}`,
                            confirmText: "Ok",
                            type: "is-danger",
                          });
                        }
                      } catch (error) {
                        setTimeout(() => {
                          this.$buefy.dialog.alert({
                            title: "Gagal !",
                            message: error.response
                              ? `${error.response.statusText}`
                              : "Terjadi Kesalahan",
                            confirmText: "Ok",
                            type: "is-danger",
                          });
                        }, 1000);
                      }
                    },
                  });
                }
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1000);
            }
          },
        });
      }
    },
    async handleSearchByCifNumberOnCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: event,
            cifMotherName: "",
            officeCode: this.property.filterDto.officeCode,
            categoryId: this.property.filterDto.categoryId,
            cifIdName: this.property.filterDto.cifIdName,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout + 500);
      }
    },
    async handleSearchByOfficeCodeOnCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cifNumber,
            cifMotherName: "",
            officeCode: event,
            categoryId: this.property.filterDto.categoryId,
            cifIdName: this.property.filterDto.cifIdName,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout + 500);
      }
    },
    async handleSearchByCifIdNameOnCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cifNumber,
            cifMotherName: "",
            officeCode: this.property.filterDto.officeCode,
            categoryId: this.property.filterDto.categoryId,
            statusId: this.property.filterDto.statusId,
            cifIdName: event,
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout + 500);
      }
    },
    async handleSearchByCategoryOnCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cifNumber,
            cifMotherName: "",
            officeCode: this.property.filterDto.officeCode,
            cifIdName: this.property.filterDto.cifIdName,
            statusId: this.property.filterDto.statusId,
            categoryId: event,
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.property.listElement.cif.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout + 500);
      }
    },
    async handleSearchByStatusAccount() {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cifNumber,
            cifMotherName: "",
            officeCode: this.property.filterDto.officeCode,
            cifIdName: this.property.filterDto.cifIdName,
            statusId: this.property.filterDto.statusId,
            categoryId: this.property.filterDto.categoryId,
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.property.listElement.cif.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout + 500);
      }
    },
    async handleDeleteDataCif(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cif/" + props.row.cifId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.cif.currentPage = 1;
                  this.property.filterDto.cifIdName = "";
                  this.property.filterDto.categoryId = "";
                  this.property.filterDto.cifNumber = "";
                  this.property.listElement.cif.rows = 0;
                  this.helperGetDataTableCif();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1000);
            }
          },
        });
      }
    },
    handleCloseModalConfirmationCif() {
      this.property.modal.showModalCheckingCif = false;
      this.handleClearModalConfirmationCif();
    },
    handleClearModalConfirmationCif() {
      this.dataForm.cifIdCardNumber = "";
      this.dataForm.cifMotherName = "";
    },
    async handleCheckingAvailabilityCif() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.cifIdCardNumber = this.dataForm.cifIdCardNumber;
        this.session.cifMotherName = this.dataForm.cifMotherName;
        this.dataForm.cifIdCardNumber = "";
        this.dataForm.cifMotherName = "";
        const timeout = 500;
        if (this.session.cifIdCardNumber === "") {
          return;
        } else {
          try {
            this.property.listElement.cif.downloading = false;
            this.property.animation.checkingCif.isLoading = true;
            const resp = await this.$store.dispatch({
              type: "GET_DATA_FIND_BY",
              endPoint: "transaction",
              reqUrl:
                "cif/idCard/" +
                (this.dataForm.tipeIdentitas !== "ktp"
                  ? this.session.cifIdCardNumber
                      .replace(/\W/gi, "")
                      .replace(/(.{4})/g, "$1 ")
                  : this.session.cifIdCardNumber),
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.dataForm.cifIdCardNumber = this.session.cifIdCardNumber;
                this.dataForm.cifMotherName = this.session.cifMotherName;
                this.session.cifIdCardNumber = "";
                this.session.cifMotherName = "";
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `CIF Dengan Id Tersebut Sudah Tersedia !`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
                return;
              } else if (resp.data.code === "DATA_NOT_FOUND") {
                this.property.modal.showModalCheckingCif = false;
                this.handleRouteToPageAddCif();
              } else {
                this.dataForm.cifIdCardNumber = this.session.cifIdCardNumber;
                this.dataForm.cifMotherName = this.session.cifMotherName;
                this.session.cifIdCardNumber = "";
                this.session.cifMotherName = "";
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, timeout);
          } catch (error) {
            console.log(error.response);
            setTimeout(() => {
              this.dataForm.cifIdCardNumber = this.session.cifIdCardNumber;
              this.dataForm.cifMotherName = this.session.cifMotherName;
              this.session.cifIdCardNumber = "";
              this.session.cifMotherName = "";
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, timeout);
          } finally {
            setTimeout(() => {
              this.property.animation.checkingCif.isLoading = false;
            }, timeout);
          }
        }
      }
    },
    handleRouteToPageAddCif() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        if (this.session.cifMotherName !== "") {
          const payload = {
            cifIdCardNumber: this.session.cifIdCardNumber,
            cifMotherName: this.session.cifMotherName,
          };
          this.$router.push(
            `/cif/new/person?refId=${this.encryptBASE64(
              JSON.stringify(payload)
            )}`
          );
        } else if (this.session.cifMotherName === "") {
          const payload = {
            cifIdCardNumber: this.session.cifIdCardNumber,
          };
          this.$router.push(
            `/cif/new/enterprise?refId=${this.encryptBASE64(
              JSON.stringify(payload)
            )}`
          );
        }
      }
    },
    handleRouteToPageEditCif(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        sessionStorage.setItem("CIFID_TO_EDIT", props.row.cifId);
        this.$router.push(
          `edit/${props.row.cifCategoryId === "1" ? "individual" : "corporate"}`
        );
      }
    },
    handleRouteToPageRincianCif(props) {
      if (props.row.cifCategoryId === "1") {
        this.$router.push(
          `/cif/${this.encryptBASE64(`${props.row.cifId}`)}/person`
        );
      } else {
        this.$router.push(
          `/cif/${this.encryptBASE64(`${props.row.cifId}`)}/enterprise`
        );
      }
    },
    handleCatchErrorCif(error) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async handleChangePaginationCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.property.filterDto.cifNumber,
            cifMotherName: "",
            officeCode: this.property.filterDto.officeCode,
            categoryId: this.property.filterDto.categoryId,
            cifIdName: this.property.filterDto.cifIdName,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    async helperGetDataTableCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: "",
            cifMotherName: "",
            officeCode: this.property.filterDto.officeCode,
            categoryId: "",
            cifIdName: "",
            statusId: "",
            page: 0,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.dataForm.totalCif = resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataCif(resp);
            this.dataForm.totalCif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorCif(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, timeout);
      }
    },
    handleChargingOptionsComponent() {
      this.options.cifCategory.push(
        { value: "", text: "Semua Kategori" },
        {
          value: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CIF_CATEGORY_PERSON,
          text: "Perorangan",
        },
        {
          value: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CIF_CATEGORY_BANK,
          text: "Perusahaan - Bank",
        },
        {
          value: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CIF_CATEGORY_NON_BANK,
          text: "Perusahaan - Non Bank",
        }
      );
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeCode;
            var text = data.officeName;
            this.options.office.push({ text, value });
            this.options.officeCsv.push({ text, value });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    handleFailedGetDataCif(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    setDefaultBranchCodeValue() {
      if (this.validationDomain === "pojok.bprshikp.co.id") {
        const getUserDataFromSession = JSON.parse(
          this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
        );
        this.property.filterDto.officeCode = getUserDataFromSession.officeCode;
      }
      console.log("value");
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.setDefaultBranchCodeValue();
    this.helperGetDataTableCif();
    this.handleChargingOptionsComponent();
    this.getReferenceOffice();
    this.getReferenceStatusAccount();
  },
};
